<template>
    <div class="pt-6 pb-0 flex-col overflow-visible">

        <div
            v-show="comments.length"
            class="flex px-6 pb-4 mb-4 -mx-6 border-b"
            v-for="(comment, index) in comments"
            :key="index"
        >
            <div class="w-12" v-if="comment">
                <v-gravatar  :default-img="getDefaultAvatar()" :size="240" class="w-auto h-auto bg-center bg-cover rounded-full " :email="getEmail(comment)" />
            </div>

            <div class="w-full px-4 overflow-hidden text-sm font-light break-words">
                {{comment.text}}
            </div>

            <div class="w-32 text-right">
                <span class="block text-sm uppercase">{{ formatCommentDate(comment.created_at) }}</span>

                <div class="flex">
                    <div class="ml-auto">
                        <div class="flex">
                            <span v-if="comment.internal" class="text-xs text-gray-700">Internal<br></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-show="!comments.length"
            class="flex pb-6"
        >
            <p><i>No comments</i></p>
        </div>

        <div class="flex items-center px-4 py-4 border-t-2">
            <div class="w-14">
                <modal-field label="Internal">
                    <checkbox @click="toggleMode"></checkbox>
                </modal-field>
            </div>
            <div class="relative items-center w-full px-4">
                <div class="relative">
                    <input v-model="comment.text" type="text" class="comment-input font-light" />
                    <emojis @append="appendEmoji($event)"></emojis>
                </div>
            </div>
            <div class="w-10">
                <button class="w-10 h-10 text-white bg-gray-700 rounded-full" @click="submit">
                    <fa transform="grow-5 left-3" :icon="['fal','paper-plane']" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import moment from 'moment';
import 'moment/locale/sv';
import Emojis from "~/components/Emojis";

export default {
    name: 'ConsignmentComments',

    components: {
        Emojis,
    },

    props: {
        consignment: {
            required: true,
            type: Object,
        }
    },

    beforeMount() {
        moment.locale('sv');
        this.fetchComments();
    },

    data() {
        return {
            internalMode: false,
            input: '',
            search: '',
            comment: {
                text: ''
            },
            hoveringComment: false,
            editMode: false,
            entityComments: [],
        };
    },

    computed: {

        comments() {
            return this.entityComments.sort(function(a, b) {
                const dateA = new Date(a.created_at), dateB = new Date(b.created_at);
                return dateA - dateB;
            });
        },

        uuid() {
            return this.$route.params.uuid;
        },

        user() {
            return this.$store.getters["auth/user"];
        },

        modeClass() {
            if (this.internalMode) {
                return 'mode-switcher-right'
            }

            return 'mode-switcher-left';
        },

        externalClass() {
            if (this.internalMode) {
                return 'text-grey'
            }
            return 'text-white'
        },

        internalClass() {
            if (! this.internalMode) {
                return 'text-grey'
            }
            return 'text-white'
        }
    },

    methods: {

        getEmail(comment) {
            if(comment.user != null) {
                return comment.user.email;
            } else {
                return 'Removed user';
            }
        },
        getDefaultAvatar() {
            let url = window.location.origin;
            const image = '/images/avatar-dark.png';

            if (window.location.origin.includes('.test')) {
                url = 'https://globl.se'
            }

            url = url + image;
            return url;
        },
        formatCommentDate(date) {
            if (!date) {
                return null;
            }

            return moment(date).format("DD MMM HH:mm");
        },
        appendEmoji(emoji) {
            this.comment.text += emoji;
        },

        commentClass(comment) {
            if (comment.internal) {
                return 'border border-dashed'
            }
        },

        resetCommentInput() {
            this.comment.text = '';
            this.editMode = false;
        },

        toggleMode() {
            this.internalMode = !this.internalMode;
        },

        showCommentOptions(comment) {
            if (this.hoveringComment != comment.id) {
                return false;
            }

            if (this.user.id != comment.user.data.id) {
                return false;
            }

            return true;
        },

        commentDate(date) {
            return moment(date).format('DD MMM HH:MM')
        },

        hovering(comment, enter) {
            this.hoveringComment = enter ? comment : false;
        },

        editComment(comment) {
            this.editMode = comment.id;
            this.comment.text = comment.text;
        },

        getInitials(user) {
            let initials = '';
            let split = user.name.split(" ");
            split.forEach(element => {
                initials += element[0]
            });

            return initials;
        },

        submit() {
            if (this.comment.text == '') {
                return false;
            }

            if (this.editMode) {
                this.updateComment();
            } else {
                this.storeComment();
            }
        },

        async storeComment() {

            const params = {
                'text': this.comment.text,
                'internal': this.internalMode,
                'commentable_id': this.consignment.id,
                'commentable_type': this.consignment.morph_class,
            };

            await axios.post(this.$apiUrl.comment, params);
            this.resetCommentInput();
            this.fetchComments(true);
        },

        async deleteComment(comment) {
            await axios.delete(`${this.$apiUrl.comments}/` + comment.id);
            this.fetchComments();
        },

        async updateComment() {

            const params = {
                'text': this.comment.text,
            };

            await axios.patch(`${this.$apiUrl.comments}/` + this.editMode, params);
            this.resetCommentInput();
            this.fetchComments();
        },


        fetchComments(scroll = false) {
            this.fetchEntityComments(scroll);
        },

        fetchEntityComments(scroll) {
            this.fetch(this.consignment.id, this.consignment.morph_class, 'entityComments', scroll)
        },

        async fetch(id, type, save, scroll) {

            const params = {
                include: 'user',
                commentable_id: id,
                commentable_type: type,
            };

            const { data } = await axios.get(this.$apiUrl.comments, {params: params}).finally(() => {
                this.loading = false;
            });
            this[save] = data.data

            if (scroll) {
                setTimeout(function(){
                    const element = document.getElementById('comments-container');
                    element.scrollTop = element.scrollHeight;
                }, 1);

            }

        },
    }
};
</script>

<style scoped>
.comment-input {
    padding-right: 3rem !important;
}
</style>
