<template>
   <emoji-picker class="flex emoji-position" :search="search">
        <div
            class="flex my-auto mr-2 outline-none cursor-pointer emoji-invoker"
            slot="emoji-invoker"
            slot-scope="{ events: { click: clickEvent } }"
            @click.stop="clickEvent"
        >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.47581 0 0 4.47581 0 10C0 15.5242 4.47581 20 10 20C15.5242 20 20 15.5242 20 10C20 4.47581 15.5242 0 10 0ZM10 18.7097C5.16129 18.7097 1.29032 14.8387 1.29032 10C1.29032 5.20161 5.16129 1.29032 10 1.29032C14.7984 1.29032 18.7097 5.20161 18.7097 10C18.7097 14.8387 14.7984 18.7097 10 18.7097ZM13.629 12.8226C12.7016 13.9113 11.371 14.5161 10 14.5161C8.58871 14.5161 7.25806 13.9113 6.33065 12.8226C6.12903 12.5403 5.72581 12.5403 5.44355 12.7419C5.16129 12.9839 5.12097 13.3871 5.3629 13.6694C6.49194 15.0403 8.18548 15.8065 10 15.8065C11.7742 15.8065 13.4677 15.0403 14.5968 13.6694C14.8387 13.3871 14.7984 12.9839 14.5161 12.7419C14.2742 12.5403 13.8306 12.5403 13.629 12.8226ZM6.77419 9.35484C7.45968 9.35484 8.06452 8.79032 8.06452 8.06452C8.06452 7.37903 7.45968 6.77419 6.77419 6.77419C6.04839 6.77419 5.48387 7.37903 5.48387 8.06452C5.48387 8.79032 6.04839 9.35484 6.77419 9.35484ZM13.2258 9.35484C13.9113 9.35484 14.5161 8.79032 14.5161 8.06452C14.5161 7.37903 13.9113 6.77419 13.2258 6.77419C12.5 6.77419 11.9355 7.37903 11.9355 8.06452C11.9355 8.79032 12.5 9.35484 13.2258 9.35484Z" fill="#929292"/>
            </svg>
        </div>
        <div slot="emoji-picker" slot-scope="{ emojis }">
            <div class="absolute z-10 w-64 p-4 overflow-scroll bg-white border rounded shadow emoji-container h-96 t-4 -r-64">
                <div class="flex">
                    <input class="flex-1 w-full px-4 py-2 border rounded-full outline-none focus:shadow-outline" type="text" v-model="search" v-focus>
                </div>
                <div>
                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                        <h5 class="mt-4 mb-2 text-sm uppercase cursor-default text-grey-darker">{{ category }}</h5>
                        <div class="flex flex-wrap justify-between emojis">
                            <button
                                    class="flex items-center justify-center w-6 h-6 p-1 rounded cursor-pointer hover:bg-grey-light focus:outline-none focus:shadow-outline"
                                    v-for="(emoji, emojiName) in emojiGroup"
                                    :key="emojiName"
                                    @click="append(emoji)"
                                    :title="emojiName"
                            >{{ emoji }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </emoji-picker>
</template>

<script>
    import EmojiPicker from 'vue-emoji-picker'
    import SmileyIcon from '~/components/SmileyIcon'

    export default {
        name: 'Emojis',

        components: {
            EmojiPicker,
            SmileyIcon
        },

        data() {
            return {
                search: '',
            };
        },

        methods: {
            append(emoji) {
                this.$emit('append', emoji);
            }
        },

        directives: {
            focus: {
                inserted(el) {
                    el.focus()
                },
            },
        },

    };
</script>


<style scoped>
    .emoji-container {
        top: -504px;
        left: -225px;
        height: 500px;
    }

    .emoji-position {
        position: absolute;
        right: 0.25rem;
        top: 0;
        bottom: 0;
    }

    .emoji-invoker { 
        transition: all 0.2s;
        background-color: #fff;
    }
    .emoji-invoker:hover { 
        transform: scale(1.1);
    }

    .emojis:after {
        content: ""; 
        flex: auto
    }
</style>