<template>
    <div class="flex mb-24 items-start">
        <div class="w-1/2">
            <template v-for="(label, labelIndex) in data.labels">
                <img v-if="data && label.extension === 'PNG'" :src="labelSource(label)" :key="labelIndex" class="mb-4">
            </template>
            <p class="text-xl text-gray-600 text-center mt-24" v-if="data.labels[0].extension !== 'PNG'">No preview available</p>
        </div>

        <card title="Good job!" class="relative w-2/3 ml-4">
            <div class="w-full pb-24">
                <div class="flex items-center justify-center w-full my-8">
                    <fa :icon="['fal', 'link']" class="mr-2 text-6xl"/>
                </div>

                <div class="flex flex-col max-w-lg mx-auto">
                    <p>
                        Shipment {{ consignment.consignment_no }} is now booked and the carrier is informed. Print the label and attach it to your goods.
                        Here below you can add additional information to your shipment.
                    </p>


                    <div class="mt-12">
                        <div class="flex items-center w-full pb-2 border-b cursor-pointer" @click="trackingLinkAccordion = !trackingLinkAccordion">
                            <fa :icon="['fal', 'link']" class="mr-2"/>
                            <p>Send tracking link</p>
                            <div class="ml-auto">
                                <fa :icon="['fal', trackingLinkAccordion ? 'chevron-up' : 'chevron-down']" class="mr-2"/>
                            </div>
                        </div>

                        <div v-if="trackingLinkAccordion" class="mt-6">
                            <p>Send to a contact person and/or anyone you want...</p>

                            <div class="mt-6" v-if="contacts.length > 0">
                                <div v-for="(contactPerson, index) in contactPersons" :key="`contact-person_${index}`" class="mb-2">
                                    <multi-select v-model="contactPerson.contact" :options="contacts" select-label="name" select-value="email"></multi-select>
                                </div>
                                <div class="cursor-pointer flex items-center mt-2" @click="addContactPerson()">
                                    <fa :icon="['fal', 'plus']" class="mr-2"/>
                                    <span>Add contact person</span>
                                </div>
                            </div>

                            <div class="mt-6">
                                <div class="flex mb-2" v-for="(customReceiver, index) in customReceivers" :key="`custom-receiver_${index}`">
                                    <div class="w-1/2 mr-2">
                                        <multi-select v-model="customReceiver.by" :options="['E-mail']" :allow-clear="false"></multi-select>
                                    </div>

                                    <div class="w-1/2 ml-2">
                                        <t-input v-model="customReceiver.address"></t-input>
                                    </div>
                                </div>

                                <div @click="addCustomReceiver()" class="cursor-pointer flex items-center mt-2">
                                    <fa :icon="['fal', 'plus']" class="mr-2"/>
                                    <span>Add receiver</span>
                                </div>
                            </div>
                        </div>

                        <div class="flex items-center w-full pb-2 mt-6 border-b cursor-pointer" @click="commentsAccordion = !commentsAccordion">
                            <fa :icon="['fal', 'comment-alt']" class="mr-2"/>
                            <p>Comments</p>
                            <div class="ml-auto">
                                <fa :icon="['fal', commentsAccordion ? 'chevron-up' : 'chevron-down']" class="mr-2"/>
                            </div>
                        </div>

                        <div v-if="commentsAccordion" id="comments-container">
                            <consignment-comments :consignment="data" />
                        </div>
                    </div>
                </div>

            </div>

            <div class="absolute bottom-0 w-full pr-12 mb-6">
                <div class="flex">
                    <div @click="downloadLabels()" class="flex items-center justify-center w-1/2 mr-4 btn">
                        <fa :icon="['fal', 'arrow-to-bottom']" class="mr-2 text-xl"/>
                        Download
                    </div>

                    <button class="flex items-center justify-center w-1/2 ml-4 btn" :disabled="data.labels[0].extension !== 'PNG'" @click="print()">
                        <fa :icon="['fal', 'print']" class="mr-2 text-xl"/>
                        Print
                    </button>
                </div>
            </div>
        </card>
    </div>
</template>
<script>

import TInput from "~/components/TInput";
import MultiSelect from "~/components/MultiSelect";
import ConsignmentComments from "./ConsignmentComments";

export default {
    name: "Confirmation",

    components: {ConsignmentComments, MultiSelect, TInput},

    data: () => ({
        data: null,
        trackingLinkAccordion: false,
        commentsAccordion: false,
        contactPersons: [],
        customReceivers: [],
    }),

    created() {
        this.setData();

        this.addCustomReceiver();
        this.addContactPerson();
    },

    computed: {
        addresses() {
            return this.$store.getters['price-leadtime/address'];
        },

        consignment() {
            return this.$store.getters['price-leadtime/consignorShipment'];
        },

        contacts() {
            let contacts = [];

            if (this.addresses.sender.company_model) {
               contacts = [...contacts, ...this.addresses.sender.company_model.contacts];
            }

            if (this.addresses.receiver.company_model) {
                contacts = [...contacts, ...this.addresses.receiver.company_model.contacts];
            }

            return contacts;
        },
    },

    methods: {

        downloadLabels() {
            const link = document.createElement('a');

            link.setAttribute('download', null);
            link.style.display = 'none';

            document.body.appendChild(link);

            this.data.labels.forEach((label, index) => {
                link.setAttribute('download', `label_${index+1}`);
                link.setAttribute('href', this.labelSource(label));
                link.click();
            })

            document.body.removeChild(link);
        },

        labelSource(label) {
            return 'data:image/png;base64, ' + label.content
        },

        addCustomReceiver() {
            this.customReceivers.push({
                by: 'E-mail',
                address: null,
            })
        },

        addContactPerson() {
            this.contactPersons.push({
                contact: null,
            })
        },

        setData() {
            this.data = this.$store.getters[`price-leadtime/consignorShipment`];
        },

        print() {
            this.printImage();
        },

        imageToPrint(source)
        {
            let sourceString = ''
            this.data.labels.forEach((label) => {
                source = this.labelSource(label)
                sourceString += `<img src="${source}" />`
            })

            return "<html><head><scri"+"pt>function step1(){\n" +
                "setTimeout('step2()', 10);}\n" +
                "function step2(){window.print();window.close()}\n" +
                "</scri" + "pt></head><body onload='step1()'>\n" +
                sourceString +
                "</body></html>";
        },

        printImage(source) {
            const pageLink = "about:blank";
            const pwa = window.open(pageLink, "_new");
            pwa.document.open();
            pwa.document.write(this.imageToPrint(source));
            pwa.document.close();
        },
    }

}
</script>
